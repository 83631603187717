import { useDefaultGetters } from '@shared/composables/useDataList'
import digitalCodesService from '@app/api/flow/digitalCodes'
import { map, each, find, filter, orderBy } from 'lodash-es'
import store from '@shared/store'

export default {
  ...useDefaultGetters(),
  filterByProducts: (state) => (products) => {
    const vouchers = map(state.list, (entity) => {
      const product = find(store.getters['products/fetch'], {id: entity.productId})
      const productData = store.getters['products/flattenProductAttributes'](product)
      return {
        ...productData,
        ...entity,
      }
    })

    const productIds = map(products, (product) => product.id)
    return orderBy(filter(vouchers, (digitalCode) => productIds.indexOf(digitalCode.productId) >= 0), state.sortOrder.field, state.sortOrder.direction())
  },
  downloadUrl: (state) => (ids) => {
    let url = `${digitalCodesService.getRoute()}?`
    url += `filter=${ids.join(',')}`
    return url
  },
  inventories(state) {
    const inventories = []
    each(state.list, (digitalCode) => {
      const criteria = {
        id: digitalCode.inventoryId,
        name: digitalCode.inventory,
      }
      const check = find(inventories, criteria)
      if (!check) {
        inventories.push({
          ...criteria,
        })
      }
    })
    return inventories
  },
  providers(state) {
    const providers = []
    each(state.list, (digitalCode) => {
      const criteria = {
        id: digitalCode.providerId,
        name: digitalCode.provider,
      }
      const check = find(providers, criteria)
      if (!check) {
        providers.push({
          ...criteria,
        })
      }
    })
    return providers
  },
  sortOrder(state) {
    return state.sortOrder
  },
  chunkSizeForDownload(state) {
    return state.chunkSizeForDownload
  },
}
