import { useDefaultState } from '@shared/composables/useDataList'
import Pagination from '@shared/models/Pagination'
import SortOrder from '@shared/models/SortOrder'

const pagination = new Pagination(10, 1)

const sortOrder = new SortOrder('name')

export default {
  ...useDefaultState({}, pagination),
  sortOrder: sortOrder,
  chunkSizeForDownload: 700
}