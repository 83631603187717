import { useDefaultGetters } from '@shared/composables/useDataList'
import { filterEntities } from '@shared/utils/entities'
import { stringToDateTime } from '@shared/utils/date'
import productService from '@app/api/flow/products'
import { clone, each, find, filter, sortBy, isEmpty, isObject, isString, orderBy, map } from 'lodash-es'

const matchesValue = (productValue, filterValue) => {
  return String(productValue).toLowerCase().indexOf(String(filterValue).toLowerCase()) >= 0
}
const getAttributeValue = (product, field) => {
  if (product.attributes) {
    const attribute = find(product.attributes, {name: field})
    return isObject(attribute) ? attribute.value : ''
  }

  return isString(product[field]) ? product[field] : ''
}
const matchesAttributeFilter = (product, filter) => {
  let hasMatch = true

  each(filter, (value, field) => {
    if (hasMatch) {
      const attributeValue = getAttributeValue(product, field)
      hasMatch = !_.isEmpty(attributeValue) && matchesValue(attributeValue, value)
    }
  })

  return hasMatch
}
const matchesDateFilter = (product, filter) => {
  let hasMatch = true

  each(filter, (value, field) => {
    if (hasMatch && isObject(value)) {
      const dateString = getAttributeValue(product, `_Dates_${field}`)

      if (isEmpty(dateString) && (!isEmpty(value.startDate) || !isEmpty(value.endDate))) {
        hasMatch = false
      } else {
        const timestamp = stringToDateTime(dateString).getTime()
        if (!isEmpty(value.startDate)) {
          const startDate = stringToDateTime(`${value.startDate} 00:00:00`)
          hasMatch = timestamp >= startDate.getTime()
        } 
        if (hasMatch && !isEmpty(value.endDate)) {
          const endDate = stringToDateTime(`${value.endDate} 23:59:59`)
          hasMatch = timestamp <= endDate.getTime()
        }
      }
    }
  })
  return hasMatch
}
const matchesAllocationFilter = (product, retailerMapping, allocationFilters) => {
  allocationFilters.productId = product.id
  allocationFilters.deleted = 0
  return isObject(find(retailerMapping, allocationFilters))
}

const flattenProductAttributes = (state) => (entity) => {
  const product = clone(entity)
  // delete product.attributes
  each(entity.attributes, (attribute) => {
    product[attribute.name] = attribute.value
  })
  return product
}

export default {
  ...useDefaultGetters(),
  flattenProductAttributes: flattenProductAttributes,
  applyFilter: (state) => (formData) => {
    const allocationFilters = {}
    const attributeFilters = {}
    const productFilters = {}
    const dateFilters = {}

    each(formData, (value, key) => {
      if (!isEmpty(value)) {
        if (key.indexOf('___') === 0) {
          allocationFilters[key.substring(3)] = value
        } else if (key.indexOf('__') === 0) {
          dateFilters[key.substring(2)] = value
        } else if (key.indexOf('_') === 0) {
          attributeFilters[key] = value
        } else {
          productFilters[key] = value
        }
      }
    })
    
    let results = filterEntities(state.list, productFilters)

    if (!isEmpty(results) && !isEmpty(attributeFilters)) {
      results = filter(results, (product) => matchesAttributeFilter(product, attributeFilters))
    }
    if (!isEmpty(results) && !isEmpty(dateFilters)) {
      results = filter(results, (product) => matchesDateFilter(product, dateFilters))
    }
    if (!isEmpty(results) && !isEmpty(allocationFilters) && allocationFilters.retailerId) {
      const retailerId = String(allocationFilters.retailerId)
      delete allocationFilters.retailerId
      if (state.retailerMappings[retailerId]) {
        results = filter(results, (product) => {
          return matchesAllocationFilter(product, state.retailerMappings[retailerId], allocationFilters)
        })
      } else {
        results = []
      }
    }

    const products = map(results, (entity) => {
      return flattenProductAttributes(state)(entity)
    })

    return orderBy(products, state.sortOrder.field, state.sortOrder.direction())
  },
  downloadUrl: (state) => (ids) => {
    let url = `${productService.getRoute()}?`
    url += `filter=${ids.join(',')}`
    return url
  },
  getRetailerMapping: (state) => (retailerId) => {
    retailerId = String(retailerId)
    if (state.retailerMappings[retailerId]) {
      return state.retailerMappings[retailerId]
    }
    return []
  },
  attributeSelections(state) {
    const selections = {
      _Type: [],
      _Genre: [],
      _Format: [],
      _Publisher: [],
    }
    each(state.list, (product) => {
      each(selections, (arr, name) => {
        const attribute = find(product.attributes, { name })
        if (attribute) {
          const selection = {
            text: attribute.value,
            value: attribute.value,
          }
          const check = find(arr, selection)
          if (!check) {
            arr.push(selection)
            selections[name] = sortBy(arr, 'value')
          }  
        }
      })
    })
    return selections
  },
  activeTabIndex(state) {
    return state.activeTabIndex
  },
  chunkSizeForDownload(state) {
    return state.chunkSizeForDownload
  },
  sortOrder(state) {
    return state.sortOrder
  },
  getProductNameById: (state) => (id) => {
    if (state.list.length === 0) {
      return ''
    }

    const product = find(state.list, {id: id})
    return isObject(product) ? product.name : ''
  },
}
