import { useDefaultState } from '@shared/composables/useDataList'
import Pagination from '@shared/models/Pagination'
import DateRange from '@shared/models/Date/Range'
import SortOrder from '@shared/models/SortOrder'

const pagination = new Pagination(10, 1)

const sortOrder = new SortOrder('name')

export default {
  ...useDefaultState({
    sku: '',
    name: '',
    active: '',
    _Type: '',
    _Esrb: '',
    _Genre: '',
    _Format: '',
    _Publisher: '',
    __Release: new DateRange('', ''),
    __Preorder: new DateRange('', ''),
    ___retailerId: '',
  }, pagination),
  retailerMappings: {},
  activeTabIndex: 0,
  sortOrder: sortOrder,
  chunkSizeForDownload: 300
}